.main-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #e5e5e5;
    padding: 20px;
}

/* .image-area{
    display: flex;
    width: 90%;
    justify-content: space-around;
} */

.single-image{
    width: 340px;
    height: 390px;
    padding: 24px;
    background: #fff;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: .6s ease-out;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

.single-image:hover {
    transform: translateY(15px);
}

.single-image:hover::before{
    opacity: 1;
}

.single-image:hover .image-text {
    opacity: 1;
    transform: translateY(0);
}

.single-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #000, transparent);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
}

.single-image img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.single-image .image-text {
    position: relative;
    z-index: 3;
    color: #fff;
    opacity: 0;
    transform: translateY(60px);
    transition: 0.5s;
}

.image-text h2 {
    margin: 0;
    font-family: "Shrikhand";
    letter-spacing: 3px;
    font-size: 3rem;
}

.image-text p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
    font-family: "Poppins";
}

.image-text button {
    background-color: #5a8b26;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px;
    margin-top: 5px;
}

@media (max-width: 600px) {
    .single-image .image-text {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @media (max-width: 390px) {
    .single-image {
      width: 300px;
    }
  }