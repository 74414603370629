.carousel-control-prev .visually-hidden {
    display: none;
}

.carousel-control-next .visually-hidden {
    display: none;
}

.carousel-control-prev-icon {
    border: 1px solid red;
    border-radius: 50%;
    content: '';
}

.slider h2 {
  /* display: inline-block;
  color: '#639131';
  font-size: 80px;
  line-height: 90px;
  font-weight: 400;
  margin: 0 0 25px;
  transform: translateY(-0px);
  transition-delay: 1s;
  transition: transform 2s ease, opacity 2s ease;
  z-index: 2; */
  }