@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Shrikhand&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

#overlay {
    position: absolute;
    
    background-color: rgba(0,0,0,0.5);
    z-index: 2; 
  }

body, html {
  font-family: 'font-poppins';
}

  @keyframes loading {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .ft-slick__dots--custom {
    height: 20px;
    width: 20px;
    background-color: #5a8b26;
    border-radius: 2px;
    position: relative;
    margin-top: 20px;
  }

  .slick-dots li {
    width: 14px;
    margin: 0 2px;
    transition: width 0.3s ease-in-out;
    margin-top: 20px;
  }

  .slick-dots .slick-active {
    width: 56px;
    transition: width 0.3s ease-in-out;
    margin-top: 20px;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 56px;
    top: 20px;
    overflow: hidden;
    margin-top: 20px;
  }

  /* .loadings {
    height: 8px;
    animation: loading 5s ease-in;
    background-image: linear-gradient(270deg, #4fd15a, #03ac0e);
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    margin-top: 20px;
  } */


@layer utilities {
  .nav-link {
    @apply relative text-[#88878f] hover:text-black duration-300
  }

  .nav-link::after {
    @apply content-[''] bg-[#5a8b26] h-[3px] w-[0%] rounded-xl absolute left-0 -bottom-[28px] duration-300
  }

  .nav-link:hover::after{
    @apply w-[100%]
  }

  .nav-link:active{
    @apply w-[100%] 
  }

  .image-gallery {
    @apply relative block rounded-lg overflow-hidden border-2 border-red-700
  }

  .image-gallery::after {
    @apply absolute top-0 left-0 right-0 bottom-0 content-[''] rounded-lg bg-mygradient origin-top scale-y-0 z-10 transition
  }

  .image-gallery:hover, .image-gallery:hover::after {
    @apply scale-100 cursor-pointer bg-mygradient 
  }

  .links {
    @apply text-[#5a8b26] 
  }

  .links:hover {
    @apply no-underline text-[#4c751f] duration-300
  }

  .pageBtn {
    @apply bg-white border-[1px] border-[#5a8b26] text-[#5a8b26] uppercase tracking-widest py-2 px-4
  }

  .active {
    @apply border-transparent bg-[#5a8b26] text-white
  }
}

.Toastify__toast {
  text-transform: capitalize;
}